<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
      <change-administrator-password-form @on-submit="onChangeAdministratorPasswordSubmit" :administratorData="administratorData"></change-administrator-password-form>
    </div>
    <div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
      <md-button @click.native="$router.push({name: 'Edit Administrator', params: {id: $route.params.id}})" class="md-sm md-default"><md-icon>arrow_back</md-icon>Back</md-button>
    </div>
  </div>
</template>
<script>
  import ChangeAdministratorPasswordForm from '../Forms/ChangeAdministratorPasswordForm.vue'
  import swal from 'sweetalert2'

  export default {
    components: {
      ChangeAdministratorPasswordForm
    },
    data () {
      return {
        administratorData: {}
      }
    },
    created() {
      var self = this
      var xhr = new XMLHttpRequest()
      xhr.open('GET', this.$root.apiUrl + 'v1/admins/' + self.$route.params.id)
      xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
      xhr.onload = function () {
        if (xhr.status === 401) self.$router.push({name: 'Login'})
        var response = JSON.parse(xhr.responseText)
        if (xhr.status === 200 && response.response && response.response) {
          if (response.response.active === '1') response.response.active = true
          else if (response.response.active === '0') response.response.active = false
          self.administratorData = response.response
        } else if (xhr.status === 404) {
          self.$router.push({name: 'Administrators'})
        } else {
          var message
          if (response.response && response.response[0] && response.response[0].message)
            message = response.response[0].message
          else
            message = 'Unknown error'
          self.showSweetAlertError(message)
        }
      }
      xhr.send()
    },
    methods: {
      onChangeAdministratorPasswordSubmit (model, isValid) {
        if (isValid) {
          var self = this
          var formData = new FormData()
          formData.append('password', model.password)
          var xhr = new XMLHttpRequest()
          xhr.open('POST', this.$root.apiUrl + 'v1/admins/' + self.$route.params.id)
          xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
          xhr.onload = function () {
            if (xhr.status === 401) self.$router.push({name: 'Login'})
            var response = JSON.parse(xhr.responseText)
            if (xhr.status === 201) {
              self.notifyVue('top','right')
            } else {
              var message
              if (response.response && response.response[0] && response.response[0].message)
                message = response.response[0].message
              else
                message = 'Unknown error'
              self.showSweetAlertError(message)
            }
          }
          xhr.send(formData)
        }
      },
      showSweetAlertError (message) {
        swal({
          title: 'An error occured!',
          text: message,
          type: 'warning',
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Try again',
          cancelButtonClass: 'md-button md-success',
          buttonsStyling: false
        })
      },
      notifyVue (verticalAlign, horizontalAlign) {
        this.$notify(
          {
            message: 'Administrator password changed succesfully.',
            icon: 'vpn_key',
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: 'success'
          }
        )
      }
    }
  }
</script>
<style>
</style>
