<template>
  <form>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-primary">
        <div class="card-icon">
          <md-icon>vpn_key</md-icon>
        </div>
        <h4 class="title">Change <strong>{{ administratorData.name }}</strong> Password</h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">          
          <div class="md-layout-item md-size-50 mt-4 md-small-size-100 mx-auto">
            <md-field :class="[
              {'md-valid': !errors.has('password') && touched.password},
              {'md-error': errors.has('password')}]">
              <label>Password</label>
              <md-input
                v-model="password"
                data-vv-name="password"
                type="password"
                ref="password"
                required
                v-validate="modelValidations.password">
              </md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('password') && touched.password">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('password') && touched.password">done</md-icon>
              </slide-y-down-transition>
            </md-field>
            <md-field :class="[
              {'md-valid': !errors.has('confirmPassword') && touched.confirmPass},
              {'md-error': errors.has('confirmPassword')}]">
              <label>Confirm Password</label>
              <md-input
                v-model="confirmPassword"
                data-vv-name="confirmPassword"
                data-vv-as="password"
                type="password"
                required
                v-validate="modelValidations.confirmPassword">
              </md-input>
              <slide-y-down-transition>
                <md-icon class="error" v-show="errors.has('confirmPassword') && touched.confirmPassword">close</md-icon>
              </slide-y-down-transition>
              <slide-y-down-transition>
                <md-icon class="success" v-show="!errors.has('confirmPassword') && touched.confirmPassword">done</md-icon>
              </slide-y-down-transition>
            </md-field>
            <div class="form-category"><small>* Required fields</small></div>
          </div>
        </div>
      </md-card-content>

      <md-card-actions md-alignment="right">
        <md-button native-type="submit" @click.native.prevent="validate" class="md-success"><md-icon>save</md-icon>Change</md-button>
      </md-card-actions>
    </md-card>
  </form>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'

export default {
  components: {
    SlideYDownTransition
  },
  props: {
    administratorData: {
      type: Object
    }
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      touched: {        
        password: false,
        confirmPass: false
      },
      modelValidations: {        
        password: {
          required: true,
          min: 5
        },
        confirmPassword: {
          required: true,
          confirmed: 'password'
        }
      }
    }
  },
  methods: {
    validate() {
      this.$validator.validateAll().then(isValid => {
        var administratorModel = {          
          password: this.password
        }
        this.$emit('on-submit', administratorModel, isValid)
      })
    }    
  },
  watch: {    
    password(){
      this.touched.password = true
    },
    confirmPassword(){
      this.touched.confirmPass = true
    }
  }
}
</script>
<style lang="scss" scoped>
.md-card .md-card-actions{
  border: none;
}
</style>
